<template>
	<div id="wrapper" class="main" v-cloak>
		<!-- 헤더 -->
		<header id="header">
			<h1 class="logo">
				<a style="cursor: default">
					<img src="/dashboardlib/images/common/logo.png" alt="A safe / Always by your side 에이세이프" />
				</a>
			</h1>
			<span class="header_date">{{ todayDate }}</span>

			<select class="header_select" id="" name="" title="현장명 선택" @change="siteChangeEvent" v-model="nowSite.selectedSiteId">
				<option v-for="(item, index) in inqSite" :key="index" :value="item.siteId" style="color: #000">{{ item.siteNm }}</option>
			</select>
			<ul class="header_btn">
				<li v-for="(item, index) in divisionBtn" :key="index" class="btn_item" :class="[item.n, item.activeClass]">
					<a @click="divisionBtnClicked(index)"></a>
				</li>
			</ul>
		</header>
		<!-- //헤더 -->

		<!-- 본문 -->
		<div id="container">
			<!-- 왼쪽 본문 -->
			<div class="cont_left_body">
				<!-- 위험상황 & 조치중 -->
				<div class="situation">
					<ul class="situation_list">
						<li class="situation_item n1">
							<span class="situation_count">
								<em>{{ count.danger }}</em>
								건
							</span>
							<span class="situation_title">위험상황</span>
						</li>
						<li class="situation_item n2">
							<span class="situation_count">
								<em>{{ count.action }}</em>
								건
							</span>
							<span class="situation_title">조치중</span>
						</li>
					</ul>
				</div>
				<!-- //위험상황 & 조치중 -->

				<!-- CCTV목록 -->
				<div class="cctv">
					<ul class="cctv_list">
						<li v-for="(item, index) in leftCctv" :key="index" :class="[item.class]" class="cctv_item">
							<span class="cctv_name" :class="[item.type]">{{ item.cctvNm }}</span>
							<div class="switch_wrapper">
								<input type="checkbox" class="switch" :id="'switch' + index" @click="cctvOnOff(index)" />
								<label :for="'switch' + index" class="switch_label">
									<span class="onf_btn"></span>
								</label>
							</div>
						</li>
					</ul>
				</div>
				<!-- //CCTV목록 -->
			</div>
			<!-- //왼쪽 본문 -->

			<!-- 오른쪽 본문 -->
			<div class="cont_right_body">
				<!-- 오른쪽 본문 상단 -->
				<div class="cont_right_body_top">
					<!-- 주의작업 -->
					<div class="caution">
						<div class="caution_left">
							<span class="caution_title">주의작업</span>
							<span class="caution_count">
								<em>{{ count.caution }}</em>
								건
							</span>
						</div>
						<div class="caution_notice">
							<ul class="notice_list" style="width: inherit">
								<li class="notice_item" v-for="(item, index) in dangerContent" :key="index">· {{ item.text }}</li>
							</ul>
						</div>
					</div>
					<!-- //주의작업 -->

					<!-- 위험 발생 팝업 옵션 -->
					<div class="switch_wrapper" style="width: 150px; margin-left: 5px; color: white; background-color: #333646">
						<div style="text-align: center">위험 발생 팝업</div>
						<div class="row" style="display: flex; justify-content: center">
							<div class="col-lg-4">라이브&nbsp;</div>
							<div class="col-lg-4">
								<input type="checkbox" class="switch" id="danger_occur_popup_option_switch" v-model="dangerPopupOptionFlag" />
								<label for="danger_occur_popup_option_switch" class="switch_label">
									<span class="onf_btn"></span>
								</label>
							</div>
							<div class="col-lg-4">&nbsp;캡처</div>
						</div>
					</div>
					<!-- 날씨정보 -->
					<div class="weather">
						<ul class="weather_list">
							<li class="weather_item temp">
								<div class="temp_text1">{{ weather.temperature }}℃</div>
							</li>
							<li class="weather_item temp">
								<div class="temp_text2">
									<span style="display: block">↑{{ weather.temperatureMax }}℃</span>
									<span style="display: block">↓{{ weather.temperatureMin }}℃</span>
								</div>
							</li>
							<li class="weather_item rain">
								<!-- <span class="fas weather text-primary" v-bind:class="weather.weatherIcon">
								<div class="rain_icon fas fa-4x" :class="weather.weatherIcon"></div> -->
								<div class="rain_icon type01">맑음</div>
							</li>
							<li class="weather_item rain">
								<div class="rain_text">0mm</div>
							</li>
							<li class="weather_item info">
								<ul class="info_list">
									<li class="info_item"></li>
									<li class="info_item">
										습도 :
										<em>{{ weather.humidity }}</em>
										%
									</li>
									<li class="info_item">
										풍속 :
										<em>{{ weather.windSpeed }}</em>
										m/s
									</li>
								</ul>
							</li>
						</ul>
					</div>
					<!-- //날씨정보 -->
				</div>
				<!-- //오른쪽 본문 상단 -->

				<!-- cctv화면목록 -->
				<div class="screen" :class="[division]">
					<ul class="screen_list">
						<li v-for="(item, index) in screenCctv" :key="index" class="screen_item">
							<div class="screen_wrap">
								<div ref="screenBox" class="screen_box" style="display: flex">
									<img :src="item.cctvPlayUrl" />
									<!-- 위험상태 보여주기 -->
									<div
										:ref="'cctv' + item.cctvId"
										class="sliding_info"
										style="width: 100%; height: 100%; background-color: #1b1e26"
										:key="forRerendering"
									>
										<div v-for="(occurEvent, i) in item.dangerOccurEvent" :key="i">
											<div
												v-if="occurEvent.videoDangerActnDvsnCd != '03' && occurEvent.slidein == false"
												:class="'dangerOccurEvent'"
												:ref="'dangerOccurEvent' + occurEvent.dangerOccurObjectSeq"
												:id="'dangerOccurEvent' + occurEvent.dangerOccurObjectSeq"
											>
												<div class="dangerOccurEventInfos">
													<h3>{{ occurEvent.objectNm }}</h3>
													<span>{{ occurEvent.targetNm }} {{ occurEvent.dangerZoneNm }}</span>
													<br />
													<span>{{ occurEvent.dangerOccurDtm }}</span>
												</div>
											</div>
											<div
												v-if="occurEvent.videoDangerActnDvsnCd != '03' && occurEvent.slidein != false"
												:class="'dangerOccurEvent slide_in'"
												:ref="'dangerOccurEvent' + occurEvent.dangerOccurObjectSeq"
												:id="'dangerOccurEvent' + occurEvent.dangerOccurObjectSeq"
											>
												<div class="dangerOccurEventInfos">
													<h3>{{ occurEvent.objectNm }}</h3>
													<span>{{ occurEvent.targetNm }} {{ occurEvent.dangerZoneNm }}</span>
													<br />
													<span>{{ occurEvent.dangerOccurDtm }}</span>
												</div>
											</div>
											<div
												v-if="occurEvent.videoDangerActnDvsnCd == '03' && occurEvent.slidein == false"
												:class="'dangerOccurEvent_actnInProgress'"
												:ref="'dangerOccurEvent_actnInProgress' + occurEvent.dangerOccurObjectSeq"
												:id="'dangerOccurEvent_actnInProgress' + occurEvent.dangerOccurObjectSeq"
											>
												<div class="dangerOccurEventInfos">
													<h3>{{ occurEvent.objectNm }} ---- 조치중</h3>
													<span>{{ occurEvent.targetNm }} {{ occurEvent.dangerZoneNm }}</span>
													<br />
													<span>{{ occurEvent.dangerOccurDtm }}</span>
												</div>
											</div>
											<div
												v-if="occurEvent.videoDangerActnDvsnCd == '03' && occurEvent.slidein != false"
												:class="'dangerOccurEvent_actnInProgress slide_in'"
												:ref="'dangerOccurEvent_actnInProgress' + occurEvent.dangerOccurObjectSeq"
												:id="'dangerOccurEvent_actnInProgress' + occurEvent.dangerOccurObjectSeq"
											>
												<div class="dangerOccurEventInfos">
													<h3>{{ occurEvent.objectNm }} ---- 조치중</h3>
													<span>{{ occurEvent.targetNm }} {{ occurEvent.dangerZoneNm }}</span>
													<br />
													<span>{{ occurEvent.dangerOccurDtm }}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="screen_control">
									<span class="cctv_name">{{ item.cctvNm }}</span>
									<input style="display: none" type="text" name="" class="cctv_inp" value="TEXT BOX" />
									<div class="screen_btn">
										<!-- <a class="btn_fix active">고정</a> -->
										<!-- 활성화 시 active 추가 -->
										<a class="btn_full" @click="jsWindowPopup(item, 'fullScreen')">전체화면</a>
										<a class="btn_close" @click="cctvOnOff(item.fixIndex)">닫기</a>
									</div>
								</div>
								<div class="screen_popup">
									<!-- 활성화 시 active 추가 -->
									<div class="popup_box">
										<p class="popup_text1">
											- 안전난간 : 안전
											<br />
											: 안전난간은 허리높이에 있어야 하고 가운데 난간이 있다.
										</p>
										<p class="popup_text2">
											- 안전난간 : 위험
											<br />
											: 아웃트리거는 펼쳐 져 있어야한다.
										</p>
										<div class="popup_btn">
											<a class="btn type1">모범사례</a>
											<a class="btn type2">연결실패</a>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<!-- //cctv화면목록 -->
			</div>
			<!-- //오른쪽 본문 -->
		</div>
		<!-- //container -->

		<!-- 푸터 -->
		<footer id="footer">
			<span class="footer_copy">Copyright © AIKL SYSTEM Inc. All Rights Reserved.</span>
		</footer>
		<!-- //푸터 -->
	</div>
</template>

<script>
import apiIndex from '../../api/index';
import window from '../../lib/window';
import VueCookies from 'vue-cookies';
import _ from 'lodash';

const dashboardUrl = apiIndex.dashboard;
const mainUrl = apiIndex.main;

let axiosExtention;
axiosExtention;

export default {
	data: () => ({
		pageParam: {
			inqSite: '',
			inqSiteLength: '',
		},
		loginInfo: null,
		scrollPosition: null,
		todayDate: '',
		// CCTV 화면 분할 조절할 class 변수
		divisionBtn: [
			{ n: 'n1', activeClass: 'active' },
			{ n: 'n2', activeClass: '' },
			{ n: 'n3', activeClass: '' },
		],
		division: 'div4', // CCTV 화면 분할 조절할 class 변수
		count: {
			danger: 0, // 위험상황 건수
			action: 0, // 조치중 건수
			caution: 0, // 주의작업 건수
		},
		inqSite: [],
		tick: 1, // 일정 시간마다 데이터 reload 용
		nowSite: {
			selectedIndex: 0,
		},
		dangerContent: [],
		weather: {
			weatherIcon: '',
			temperature: 0,
			temperatureMin: 0,
			temperatureMax: 0,
			humidity: 0,
			windSpeed: 0,
		},
		leftCctv: [], // left layout CCTV 정보
		screenCctv: [], // 화면에 뿌릴 CCTV 정보
		// videojs options
		playerOptions: {
			autoplay: true,
			controls: false,
			muted: true,
			loop: false,
			sources: [
				{
					type: 'application/x-mpegurl',
					src: '', // 설정시 변경됨
				},
			],
		},
		// cctv 상태가 위험으로 변경 시 popup 띄우기 위해 사용
		preCctvState: [],
		maxDangerOccurEvents: 5,
		forRerendering: 0,
		// cctv상태가 위험으로 변경시 나타난 popup을 x초 뒤에 없앰
		secTimeSettingForRemovingWindow: 10,
		//img, live
		dangerPopupOption: 'img',
		//위험발생팝업 스위치용
		dangerPopupOptionFlag: null,
		originDangerOccurInfo: {},
	}),
	created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;

		// pageParam 로딩
		this.pageParam = JSON.parse(localStorage.getItem('dashboardPageParam'));
		this.loginInfo = JSON.parse(localStorage.getItem('loginUserInfo'));
		// 탭 상단 이름 변경
		let title = document.getElementsByTagName('title')[0];
		title.innerHTML = '에이세이프';

		// 나머지 pageParam 세팅
		this.pageParam.inqSiteLength = this.pageParam.inqSite.length > 0 ? 0 : -1;

		this.inqSite = this.pageParam.inqSite;
		this.selectedIndex = this.pageParam.inqSiteLength;

		this.nowSite.selectedIndex = this.inqSite.findIndex(e => e.siteId === this.pageParam.loginUserSiteId);

		this.init();
	},
	computed: {},
	mounted() {
		this.setDangerPopupOption();
	},
	watch: {
		dangerPopupOptionFlag(newVal) {
			if (newVal == true) {
				this.dangerPopupOption = 'img';
			} else {
				this.dangerPopupOption = 'live';
			}
			VueCookies.set(this.loginInfo.userId + '_dangerPopupOption', this.dangerPopupOption, { expires: 365 });
		},
	},
	methods: {
		setDangerPopupOption() {
			this.dangerPopupOption = VueCookies.get(this.loginInfo.userId + '_dangerPopupOption');
			if (this.dangerPopupOption == 'img') {
				this.dangerPopupOptionFlag = true;
			} else {
				this.dangerPopupOptionFlag = false;
			}
		},
		addZero(string) {
			// 값이 1자리면 앞에 '0' 붙임
			return string[1] ? string : '0' + string[0];
		},
		clock() {
			// 현재 시각
			const date = new Date();
			const yyyy = date.getFullYear().toString();
			const mm = (date.getMonth() + 1).toString();
			const dd = date.getDate().toString();
			const h = date.getHours().toString();
			const m = date.getMinutes().toString();
			const s = date.getSeconds().toString();
			const week = ['일', '월', '화', '수', '목', '금', '토'];

			this.todayDate =
				yyyy +
				'.' +
				this.addZero(mm) +
				'.' +
				this.addZero(dd) +
				'. ' +
				week[date.getDay()] +
				' ' +
				this.addZero(h) +
				':' +
				this.addZero(m) +
				':' +
				this.addZero(s);

			// 30초마다 reload?
			if (this.tick++ % 5 !== 0) return;

			// cctv 정보도 가져와야하나..? 그럴 필요까지는 없어보이기는 함
			this.reload(this.nowSite.siteId);

			this.tick = 1;
		},
		init() {
			this.clock(); // 딜레이 없이 바로 표시하기 위함
			setInterval(() => {
				this.clock();
				this.refreshCctv();
			}, 1000); // 1초 마다 시간 변경
			// }, 60000); // 1분 마다 시간 변경

			this.initSite(this.nowSite.selectedIndex);
		},
		initSite(selectedIndex) {
			// 현장이 변경되면 최초 1회 현재 현장 정보를 선택된 현장 정보로 변경
			if (selectedIndex > -1) {
				this.nowSite = this.inqSite[selectedIndex];
				this.nowSite.selectedSiteId = this.inqSite[selectedIndex].siteId;

				this.initCctv(this.nowSite.siteId);
				this.reload(this.nowSite.siteId);
			}
		},
		initCctv(siteId) {
			// 현장이 변경되면 최초 1회 CCTV 정보 가져오기
			const param = {
				siteId: siteId, // 검색 조건
			};

			// CCTV 정보를 가져온다.
			this.$axios
				.post(dashboardUrl.inqCctv, param)
				.then(
					function (response) {
						response.data.forEach((e, index) => {
							e.fixIndex = index;
							e.playerOptions = $.extend(true, {}, this.playerOptions); // deep 복사 안하면 전부 같은 주소를 본다
							e.playerOptions.sources[0].src = e.cctvLivePath;
						});
						this.leftCctv = response.data;
						var count = 0;
						this.leftCctv.forEach(e => {
							e.cctvPlayUrl += '/' + e.cctvId;
							// cctv class 초기화
							e.type = 'type2';
							this.$nextTick(function () {
								this.cctvOnOff(count++);
							});
						});
					}.bind(this),
				)
				.catch(err => {
					console.log(err);
				});
		},
		removeDangerOccurEventWhenNumberOfEventsOverMax(cctvInfo) {
			if (cctvInfo.dangerOccurEvent != undefined) {
				if (cctvInfo.dangerOccurEvent.length > this.maxDangerOccurEvents) {
					cctvInfo.dangerOccurEvent.splice(0, cctvInfo.dangerOccurEvent.length - this.maxDangerOccurEvents);
					this.forRerendering += 1;
				}
			}
		},
		setFontSizeDangerOccurEventInfos() {
			setInterval(() => {
				let div = document.getElementsByClassName('sliding_info');
				if (div != undefined && div.length > 0) {
					let slideWidth = parseInt(div[0].offsetWidth);

					if (slideWidth > 250) {
						$('.dangerOccurEventInfos').css('font-size', '25px');
					} else if (slideWidth > 150) {
						$('.dangerOccurEventInfos').css('font-size', '12px');
					} else {
						$('.dangerOccurEventInfos').css('font-size', '5px');
					}
				}
			}, 1);
		},
		setDangerOccurEventInfos(cctvInfo, data) {
			this.originDangerOccurInfo = _.cloneDeep(cctvInfo.dangerOccurEvent);
			cctvInfo.dangerOccurEvent = [];
			//위험발생상황 추가하기
			data.inqDashboardDangerOccurOutVO.forEach(danger => {
				if (danger.cctvId == cctvInfo.cctvId) {
					if (danger.frameImgSeq != cctvInfo.dangerOccurEvent.frameImgSeq || cctvInfo.dangerOccurEvent.objectId != danger.objectId) {
						cctvInfo.dangerOccurEvent.push(danger);
					}
				}
			});
			cctvInfo.dangerOccurEvent.forEach(danger => {
				this.originDangerOccurInfo.forEach(originDanger => {
					if (danger.dangerOccurObjectSeq == originDanger.dangerOccurObjectSeq) {
						danger.slidein = false;
					}
				});
			});
			this.removeDangerOccurEventWhenNumberOfEventsOverMax(cctvInfo);
			this.setFontSizeDangerOccurEventInfos();
		},
		reload(siteId) {
			// 주기적으로 날씨, 건수, 주의 작업 텍스트 가져옴
			// 날씨
			if (navigator.geolocation) {
				this.handleGeoSucces();
			} else {
				this.handleGeoError;
			}

			// 위험상황, 조치중, 주의 작업, 금일 발생, 조치 완료, 잔여 + 주의작업 contents 가져오기 (DB)

			const param = {
				siteId: siteId, // 검색 조건
			};
			// 위험상황, 조치중, 주의 작업 건수 + 주의작업 contents 가져온다
			this.$axios
				.post(dashboardUrl.reload, param)
				.then(
					function (response) {
						this.count.danger = response.data.dangerOccurCnt; //위험상황
						this.count.action = response.data.dangerActionCnt; // 조치중
						this.count.caution = response.data.warnWorkOccurCnt; // 주의작업

						// 주의 작업 내용 편집
						response.data.inqDashboardWarnWorkOutVOs.forEach(e => {
							e.text = '[' + e.cctvInstallPlaceCont + ']';
							e.text += ' ' + e.objectNm + ' 진행 중';
						});

						this.dangerContent = response.data.inqDashboardWarnWorkOutVOs;
						// cctv 색 변경
						this.leftCctv.forEach(e => {
							// //위험발생상황 추가하기
							this.setDangerOccurEventInfos(e, response.data);
							let isDanger = false;
							for (let i = 0; i < response.data.dangerOccurCctvIds.length; i++) {
								if (e.cctvId === response.data.dangerOccurCctvIds[i]) {
									// 조회된 값이면 빨간색으로 바뀸
									isDanger = true;
									break;
								}
							}
							//위험상황시에만 CCTV가 빨갛게 바뀌도록 수정.
							//FIX ME: 주의상황시에도 해당이 되는지?
							// for (let i = 0; i < response.data.warnWorkOccurCctvIds.length; i++) {
							// 	if (e.cctvId === response.data.warnWorkOccurCctvIds[i]) {
							// 		isDanger = true;
							// 		break;
							// 	}
							// }
							if (isDanger) {
								e.type = 'type1';
							} else {
								e.type = 'type2';
							}
						});

						// FIX ME: 캡처 시 팝업이 옆에 슬라이드 보다 살짝 늦게뜨는 구조임.. 구조 변경 필요
						this.chkPopupCctv(response.data);
					}.bind(this),
				)
				.catch(err => {
					console.log(err);
				});
		},
		handleGeoSucces() {
			this.getWeather(this.nowSite.latitude, this.nowSite.longitude);
		},
		handleGeoError() {
			console.log("Can't access geo location");
		},
		getWeather(lat, log) {
			const API_KEY = 'f42c8c69cb4e3987a7a8c8cc2eb15cc4';
			fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${log}&appid=${API_KEY}&units=metric`)
				.then(response => {
					return response.json();
				})
				.then(resultJson => {
					const weatherData = resultJson;
					this.weather.weatherIcon = this.getWeatherIcon(weatherData.weather[0].icon);
					this.weather.temperature = Math.round(weatherData.main.temp);

					this.weather.temperatureMin = Math.round(weatherData.main.temp_min);
					this.weather.temperatureMax = Math.round(weatherData.main.temp_max);

					this.weather.humidity = weatherData.main.humidity;
					this.weather.windSpeed = weatherData.wind.speed;

					if (weatherData.rain) {
						this.weather.rain = weatherData.rain;
					}
				});
		},
		getWeatherIcon(iconState) {
			switch (iconState) {
				case '01d':
					return 'fa-sun';
				case '02d':
					return 'fa-cloud-sun';
				case '03d':
					return 'fa-cloud';
				case '04d':
					return 'fa-cloud';
				case '09d':
					return 'fa-cloud-showers-heavy';
				case '10d':
					return 'fa-umbrella';
				case '11d':
					return 'fa-poo-storm';
				case '13d':
					return 'fa-snowflake';
				case '50d':
					return 'fa-smog';
			}
		},
		// cctv상태가 위험으로 변경되면 popup띄움
		chkPopupCctv(data) {
			let that = this;

			// 이전 cctv 상태가 비어있으면 현재 상태로 세팅
			if (this.preCctvState.length != this.leftCctv.length) {
				this.preCctvState = JSON.parse(JSON.stringify(this.leftCctv));
			} else {
				this.leftCctv.forEach(cctv => {
					for (let i = 0; i < that.preCctvState.length; i++) {
						if (cctv.cctvId === that.preCctvState[i].cctvId) {
							if (cctv.type !== that.preCctvState[i].type && cctv.type === 'type1') {
								that.dangerCctvPopup(cctv, 'dangerOccurPopup', data);
							}
							that.preCctvState[i].type = cctv.type;
						}
					}
				});
			}
		},
		async dangerCctvPopup(item, flag, data) {
			for (let i = 0; i < data.inqDashboardDangerOccurOutVO.length; i++) {
				// 위험이 발생한 cctv의 관련 정보를 넣어줌
				if (item.cctvId === data.inqDashboardDangerOccurOutVO[i].cctvId) {
					item.objectId = data.inqDashboardDangerOccurOutVO[i].objectId;
					item.objectNm = data.inqDashboardDangerOccurOutVO[i].objectNm;
					item.targetId = data.inqDashboardDangerOccurOutVO[i].targetId;
					item.targetNm = data.inqDashboardDangerOccurOutVO[i].targetNm;
					item.siteId = data.inqDashboardDangerOccurOutVO[i].siteId;
					item.strtFrameImgSeq = data.inqDashboardDangerOccurOutVO[i].strtFrameImgSeq;
					break;
				}
			}
			if (this.dangerPopupOption == 'img') {
				await this.$axios
					.post(mainUrl.imgRealtimeEvent, item)
					.then(r => {
						item.dangerOccurImg = 'data:image/jpg;base64,' + r.data.imgVO.imgByte;
					})
					.catch(axiosExtention.buildErrorHandler());
			}
			await this.jsWindowPopup(item, flag);
		},
		// CCTV 화면 레이아웃 변경
		divisionBtnClicked(_index) {
			this.divisionBtn.forEach((e, index) => {
				if (_index === index) {
					e.activeClass = 'actvie';
					// click index에 따라 cctv layout 변경
					if (index === 0) {
						this.division = 'div1';
					} else if (index === 1) {
						this.division = 'div4';
					} else if (index === 2) {
						this.division = 'div9';
					}
				} else {
					e.activeClass = '';
				}
			});
		},
		// 왼쪽 cctv 및 스크린에서 cctv close 클릭 시
		cctvOnOff(fixIndex) {
			let cctvSwitch = document.getElementById('switch' + fixIndex);
			if (this.leftCctv[fixIndex].isOn) {
				this.leftCctv[fixIndex].class = ''; // 배경 색 없애기
				this.screenCctv.splice(this.findIndex(fixIndex), 1); // screenCctv 항목 제거
				cctvSwitch.checked = false;
			} else {
				this.leftCctv[fixIndex].class = 'leftCctv'; // 배경 색 칠하기
				this.screenCctv.push(this.leftCctv[fixIndex]); // screenCctv 항목 추가
				cctvSwitch.checked = true;
			}
			this.leftCctv[fixIndex].isOn = !this.leftCctv[fixIndex].isOn;
		},
		// 왼쪽 cctv 및 스크린에서 cctv close 클릭 시
		cctvFullScreen(fixIndex) {
			$('.screen_list').scrollTop(719 * this.findIndex(fixIndex));
			console.log(719 * this.findIndex(fixIndex), this.findIndex(fixIndex));
		},
		// fixIndex로 현재 screenCctv의 index 구하기
		findIndex(fixIndex) {
			return this.screenCctv.findIndex(e => e.fixIndex === fixIndex);
		},
		// select box change 이벤트 발생 시 site 정보 변경
		siteChangeEvent() {
			// 열려있는 모든 CCTV 끄기
			for (let i = 0; i < this.leftCctv.length; i++) {
				this.leftCctv[i].class = '';
				this.screenCctv.splice(this.findIndex(i), 1); // screenCctv 항목 제거
				document.getElementById('switch' + i).checked = false;
			}
			this.initSite(this.inqSite.findIndex(e => e.siteId === this.nowSite.selectedSiteId));
		},
		ended(player) {
			console.log('ended event', player);

			player.play(); // loop로 하면 ended event가 발생하지 않는다.
		},
		//player.currentTime(300000); 현재 시간을 해당 구역으로 보내는 기능인데.. 설정한다고 모듈에 동작할지는 모르겠음
		onPlayerPlay(player) {
			console.log('play event', player);
		},
		onPlayerWaiting(onPlayerWaiting) {
			console.log('onPlayerPlay', onPlayerWaiting);
		},
		playerStateChanged(playerCurrentState) {
			console.log('state changed', playerCurrentState);
		},
		removeWindow(popupDiv) {
			setTimeout(() => {
				popupDiv.style.display = 'none';
				// popupDiv.remove();
			}, this.secTimeSettingForRemovingWindow * 1000);
		},
		jsWindowPopupCheck(item, divScreen, popupOption, flag) {
			let popupChk = document.getElementById(flag + item.cctvId);
			let popupDiv;
			//해당 팝업이 이미 있으면 있던 걸 보여줌
			if (popupChk) {
				popupDiv = popupChk;
				let imgTag = popupDiv.querySelectorAll('img')[0];
				let titleBar = popupDiv.querySelectorAll('.window-js-title')[0];
				let titleName = '';
				if (flag == 'dangerOccurPopup' && this.dangerPopupOption == 'img') {
					imgTag.src = item.dangerOccurImg;
				} else {
					imgTag.src = item.cctvPlayUrl;
				}
				if (flag == 'dangerOccurPopup') {
					titleName = '위험 발생 ' + item.cctvNm + ': ' + item.objectNm + ' - ' + item.targetNm;
				} else {
					titleName = item.cctvNm;
				}
				titleBar.setAttribute('title', titleName);
				titleBar.textContent = titleName;
				window.show(popupDiv);
				//없으면 새로 만듦
			} else {
				popupDiv = document.createElement('div');
				popupDiv.id = flag + item.cctvId;
				divScreen.appendChild(popupDiv);

				window.create(popupDiv, popupOption);

				// video Element 생성
				let videoImg = document.createElement('img');
				// video src 세팅
				if (flag == 'dangerOccurPopup' && this.dangerPopupOption == 'img') {
					videoImg.src = item.dangerOccurImg;
				} else {
					videoImg.src = item.cctvPlayUrl;
				}
				// 'data:image/jpg;base64,' + r.data.imgVO.imgByte;
				// js window content에 video 붙여넣기
				let windowContent = window.getWindowContent();
				if (windowContent) {
					windowContent.appendChild(videoImg);
					windowContent.style.display = 'flex';
					window.show(popupDiv);
				}
			}
			return popupDiv;
		},
		jsWindowPopup(item, flag) {
			let divScreen = document.getElementsByClassName('screen_list');
			if (divScreen.length > 0) {
				divScreen = divScreen[0];
			} else {
				return;
			}
			let popupOption = {};
			if (flag == 'dangerOccurPopup') {
				popupOption = {
					width: '65vw',
					height: '80vh',
					title: '위험 발생 ' + item.cctvNm + ': ' + item.objectNm + ' - ' + item.targetNm,
				};
				let popupDiv = this.jsWindowPopupCheck(item, divScreen, popupOption, flag);
				this.removeWindow(popupDiv);
			} else if (flag == 'fullScreen') {
				popupOption = {
					width: '65vw',
					height: '80vh',
					title: item.cctvNm,
				};
				this.jsWindowPopupCheck(item, divScreen, popupOption, flag);
			}
		},
		refreshCctv() {
			this.screenCctv.forEach(e => {
				e.cctvPlayUrl = e.cctvPlayUrl.split('?t=')[0] + '?t=' + new Date().getTime();
			});
		},
	},
};
</script>

<style scoped>
a {
	cursor: pointer;
}
.leftCctv {
	background: #1a1e36;
	background-clip: content-box;
}

@keyframes slidein {
	from {
		margin-top: 20%;
		/* margin-right: 30%; */
		margin-left: 30%;
		width: 50%;
	}
	to {
		margin-top: 0%;
		/* margin-right: 0%; */
		margin-left: 0%;
		width: 100%;
	}
}

.sliding_info {
	overflow: auto;
}
.sliding_info::-webkit-scrollbar {
	width: 5px;
}
.sliding_info::-webkit-scrollbar-thumb {
	background-color: white;
	border-radius: 10px;
}
.sliding_info::-webkit-scrollbar-track {
	background-color: #202127;
	border-radius: 10px;
	/* box-shadow: inset 0px 0px 5px white; */
}

.slide_in {
	animation-duration: 1s;
	animation-name: slidein;
}

.dangerOccurEvent {
	width: 100%;
	height: 18%;
	padding: 3px;
	word-break: normal;
	margin-top: 2px;
	margin-bottom: 4px;
	border: 0.3px solid #202127;
	border-radius: 10px;
	box-shadow: 2px 2px 2px gray;
	background-color: #e56060;
}

.dangerOccurEvent_actnInProgress {
	width: 100%;
	height: 18%;
	padding: 3px;
	word-break: normal;
	margin-top: 2px;
	margin-bottom: 4px;
	border: 0.3px solid #202127;
	border-radius: 10px;
	box-shadow: 2px 2px 2px gray;
	background-color: greenyellow;
}
.dangerOccurEventInfos {
	width: 100%;
	height: 100%;
	font-size: 10px;
	line-height: normal;
	text-align: left;
	overflow: auto;
}

.switch_wrapper {
	position: relative;
	float: right;
	padding-top: 5px;
}
.switch {
	position: absolute;
	/* hidden */
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.switch_label {
	position: relative;
	cursor: pointer;
	display: inline-block;
	width: 50px;
	height: 20px;
	background: #3f425648;
	border: 2px solid rgb(255, 255, 255);
	border-radius: 20px;
	transition: 0.2s;
}
.switch_label:hover {
	background: #cccbcb;
}
.onf_btn {
	position: absolute;
	top: 1px;
	left: 4px;
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 20px;
	background: rgb(255, 255, 255);
	transition: 0.2s;
}

.switch:checked + .switch_label {
	background: rgb(255, 255, 255);
	border: 2px solid rgb(255, 255, 255);
}

.switch:checked + .switch_label:hover {
	background: #cccbcb;
}

/* move */
.switch:checked + .switch_label .onf_btn {
	left: 30px;
	background: rgb(31, 31, 31);
	box-shadow: 1px 2px 3px #00000020;
}
</style>
