var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main", attrs: { id: "wrapper" } }, [
    _c("header", { attrs: { id: "header" } }, [
      _vm._m(0),
      _c("span", { staticClass: "header_date" }, [
        _vm._v(_vm._s(_vm.todayDate))
      ]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.nowSite.selectedSiteId,
              expression: "nowSite.selectedSiteId"
            }
          ],
          staticClass: "header_select",
          attrs: { id: "", name: "", title: "현장명 선택" },
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.nowSite,
                  "selectedSiteId",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              _vm.siteChangeEvent
            ]
          }
        },
        _vm._l(_vm.inqSite, function(item, index) {
          return _c(
            "option",
            {
              key: index,
              staticStyle: { color: "#000" },
              domProps: { value: item.siteId }
            },
            [_vm._v(_vm._s(item.siteNm))]
          )
        }),
        0
      ),
      _c(
        "ul",
        { staticClass: "header_btn" },
        _vm._l(_vm.divisionBtn, function(item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "btn_item",
              class: [item.n, item.activeClass]
            },
            [
              _c("a", {
                on: {
                  click: function($event) {
                    return _vm.divisionBtnClicked(index)
                  }
                }
              })
            ]
          )
        }),
        0
      )
    ]),
    _c("div", { attrs: { id: "container" } }, [
      _c("div", { staticClass: "cont_left_body" }, [
        _c("div", { staticClass: "situation" }, [
          _c("ul", { staticClass: "situation_list" }, [
            _c("li", { staticClass: "situation_item n1" }, [
              _c("span", { staticClass: "situation_count" }, [
                _c("em", [_vm._v(_vm._s(_vm.count.danger))]),
                _vm._v(" 건 ")
              ]),
              _c("span", { staticClass: "situation_title" }, [
                _vm._v("위험상황")
              ])
            ]),
            _c("li", { staticClass: "situation_item n2" }, [
              _c("span", { staticClass: "situation_count" }, [
                _c("em", [_vm._v(_vm._s(_vm.count.action))]),
                _vm._v(" 건 ")
              ]),
              _c("span", { staticClass: "situation_title" }, [_vm._v("조치중")])
            ])
          ])
        ]),
        _c("div", { staticClass: "cctv" }, [
          _c(
            "ul",
            { staticClass: "cctv_list" },
            _vm._l(_vm.leftCctv, function(item, index) {
              return _c(
                "li",
                { key: index, staticClass: "cctv_item", class: [item.class] },
                [
                  _c("span", { staticClass: "cctv_name", class: [item.type] }, [
                    _vm._v(_vm._s(item.cctvNm))
                  ]),
                  _c("div", { staticClass: "switch_wrapper" }, [
                    _c("input", {
                      staticClass: "switch",
                      attrs: { type: "checkbox", id: "switch" + index },
                      on: {
                        click: function($event) {
                          return _vm.cctvOnOff(index)
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "switch_label",
                        attrs: { for: "switch" + index }
                      },
                      [_c("span", { staticClass: "onf_btn" })]
                    )
                  ])
                ]
              )
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "cont_right_body" }, [
        _c("div", { staticClass: "cont_right_body_top" }, [
          _c("div", { staticClass: "caution" }, [
            _c("div", { staticClass: "caution_left" }, [
              _c("span", { staticClass: "caution_title" }, [
                _vm._v("주의작업")
              ]),
              _c("span", { staticClass: "caution_count" }, [
                _c("em", [_vm._v(_vm._s(_vm.count.caution))]),
                _vm._v(" 건 ")
              ])
            ]),
            _c("div", { staticClass: "caution_notice" }, [
              _c(
                "ul",
                {
                  staticClass: "notice_list",
                  staticStyle: { width: "inherit" }
                },
                _vm._l(_vm.dangerContent, function(item, index) {
                  return _c("li", { key: index, staticClass: "notice_item" }, [
                    _vm._v("· " + _vm._s(item.text))
                  ])
                }),
                0
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "switch_wrapper",
              staticStyle: {
                width: "150px",
                "margin-left": "5px",
                color: "white",
                "background-color": "#333646"
              }
            },
            [
              _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v("위험 발생 팝업")
              ]),
              _c(
                "div",
                {
                  staticClass: "row",
                  staticStyle: { display: "flex", "justify-content": "center" }
                },
                [
                  _c("div", { staticClass: "col-lg-4" }, [_vm._v("라이브 ")]),
                  _c("div", { staticClass: "col-lg-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dangerPopupOptionFlag,
                          expression: "dangerPopupOptionFlag"
                        }
                      ],
                      staticClass: "switch",
                      attrs: {
                        type: "checkbox",
                        id: "danger_occur_popup_option_switch"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.dangerPopupOptionFlag)
                          ? _vm._i(_vm.dangerPopupOptionFlag, null) > -1
                          : _vm.dangerPopupOptionFlag
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.dangerPopupOptionFlag,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.dangerPopupOptionFlag = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.dangerPopupOptionFlag = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.dangerPopupOptionFlag = $$c
                          }
                        }
                      }
                    }),
                    _vm._m(1)
                  ]),
                  _c("div", { staticClass: "col-lg-4" }, [_vm._v(" 캡처")])
                ]
              )
            ]
          ),
          _c("div", { staticClass: "weather" }, [
            _c("ul", { staticClass: "weather_list" }, [
              _c("li", { staticClass: "weather_item temp" }, [
                _c("div", { staticClass: "temp_text1" }, [
                  _vm._v(_vm._s(_vm.weather.temperature) + "℃")
                ])
              ]),
              _c("li", { staticClass: "weather_item temp" }, [
                _c("div", { staticClass: "temp_text2" }, [
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v("↑" + _vm._s(_vm.weather.temperatureMax) + "℃")
                  ]),
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v("↓" + _vm._s(_vm.weather.temperatureMin) + "℃")
                  ])
                ])
              ]),
              _vm._m(2),
              _vm._m(3),
              _c("li", { staticClass: "weather_item info" }, [
                _c("ul", { staticClass: "info_list" }, [
                  _c("li", { staticClass: "info_item" }),
                  _c("li", { staticClass: "info_item" }, [
                    _vm._v(" 습도 : "),
                    _c("em", [_vm._v(_vm._s(_vm.weather.humidity))]),
                    _vm._v(" % ")
                  ]),
                  _c("li", { staticClass: "info_item" }, [
                    _vm._v(" 풍속 : "),
                    _c("em", [_vm._v(_vm._s(_vm.weather.windSpeed))]),
                    _vm._v(" m/s ")
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "screen", class: [_vm.division] }, [
          _c(
            "ul",
            { staticClass: "screen_list" },
            _vm._l(_vm.screenCctv, function(item, index) {
              return _c("li", { key: index, staticClass: "screen_item" }, [
                _c("div", { staticClass: "screen_wrap" }, [
                  _c(
                    "div",
                    {
                      ref: "screenBox",
                      refInFor: true,
                      staticClass: "screen_box",
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c("img", { attrs: { src: item.cctvPlayUrl } }),
                      _c(
                        "div",
                        {
                          key: _vm.forRerendering,
                          ref: "cctv" + item.cctvId,
                          refInFor: true,
                          staticClass: "sliding_info",
                          staticStyle: {
                            width: "100%",
                            height: "100%",
                            "background-color": "#1b1e26"
                          }
                        },
                        _vm._l(item.dangerOccurEvent, function(occurEvent, i) {
                          return _c("div", { key: i }, [
                            occurEvent.videoDangerActnDvsnCd != "03" &&
                            occurEvent.slidein == false
                              ? _c(
                                  "div",
                                  {
                                    ref:
                                      "dangerOccurEvent" +
                                      occurEvent.dangerOccurObjectSeq,
                                    refInFor: true,
                                    class: "dangerOccurEvent",
                                    attrs: {
                                      id:
                                        "dangerOccurEvent" +
                                        occurEvent.dangerOccurObjectSeq
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dangerOccurEventInfos" },
                                      [
                                        _c("h3", [
                                          _vm._v(_vm._s(occurEvent.objectNm))
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(occurEvent.targetNm) +
                                              " " +
                                              _vm._s(occurEvent.dangerZoneNm)
                                          )
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(occurEvent.dangerOccurDtm)
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            occurEvent.videoDangerActnDvsnCd != "03" &&
                            occurEvent.slidein != false
                              ? _c(
                                  "div",
                                  {
                                    ref:
                                      "dangerOccurEvent" +
                                      occurEvent.dangerOccurObjectSeq,
                                    refInFor: true,
                                    class: "dangerOccurEvent slide_in",
                                    attrs: {
                                      id:
                                        "dangerOccurEvent" +
                                        occurEvent.dangerOccurObjectSeq
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dangerOccurEventInfos" },
                                      [
                                        _c("h3", [
                                          _vm._v(_vm._s(occurEvent.objectNm))
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(occurEvent.targetNm) +
                                              " " +
                                              _vm._s(occurEvent.dangerZoneNm)
                                          )
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(occurEvent.dangerOccurDtm)
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            occurEvent.videoDangerActnDvsnCd == "03" &&
                            occurEvent.slidein == false
                              ? _c(
                                  "div",
                                  {
                                    ref:
                                      "dangerOccurEvent_actnInProgress" +
                                      occurEvent.dangerOccurObjectSeq,
                                    refInFor: true,
                                    class: "dangerOccurEvent_actnInProgress",
                                    attrs: {
                                      id:
                                        "dangerOccurEvent_actnInProgress" +
                                        occurEvent.dangerOccurObjectSeq
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dangerOccurEventInfos" },
                                      [
                                        _c("h3", [
                                          _vm._v(
                                            _vm._s(occurEvent.objectNm) +
                                              " ---- 조치중"
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(occurEvent.targetNm) +
                                              " " +
                                              _vm._s(occurEvent.dangerZoneNm)
                                          )
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(occurEvent.dangerOccurDtm)
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            occurEvent.videoDangerActnDvsnCd == "03" &&
                            occurEvent.slidein != false
                              ? _c(
                                  "div",
                                  {
                                    ref:
                                      "dangerOccurEvent_actnInProgress" +
                                      occurEvent.dangerOccurObjectSeq,
                                    refInFor: true,
                                    class:
                                      "dangerOccurEvent_actnInProgress slide_in",
                                    attrs: {
                                      id:
                                        "dangerOccurEvent_actnInProgress" +
                                        occurEvent.dangerOccurObjectSeq
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dangerOccurEventInfos" },
                                      [
                                        _c("h3", [
                                          _vm._v(
                                            _vm._s(occurEvent.objectNm) +
                                              " ---- 조치중"
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(occurEvent.targetNm) +
                                              " " +
                                              _vm._s(occurEvent.dangerZoneNm)
                                          )
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(occurEvent.dangerOccurDtm)
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        }),
                        0
                      )
                    ]
                  ),
                  _c("div", { staticClass: "screen_control" }, [
                    _c("span", { staticClass: "cctv_name" }, [
                      _vm._v(_vm._s(item.cctvNm))
                    ]),
                    _c("input", {
                      staticClass: "cctv_inp",
                      staticStyle: { display: "none" },
                      attrs: { type: "text", name: "", value: "TEXT BOX" }
                    }),
                    _c("div", { staticClass: "screen_btn" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn_full",
                          on: {
                            click: function($event) {
                              return _vm.jsWindowPopup(item, "fullScreen")
                            }
                          }
                        },
                        [_vm._v("전체화면")]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "btn_close",
                          on: {
                            click: function($event) {
                              return _vm.cctvOnOff(item.fixIndex)
                            }
                          }
                        },
                        [_vm._v("닫기")]
                      )
                    ])
                  ]),
                  _vm._m(4, true)
                ])
              ])
            }),
            0
          )
        ])
      ])
    ]),
    _vm._m(5)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "logo" }, [
      _c("a", { staticStyle: { cursor: "default" } }, [
        _c("img", {
          attrs: {
            src: "/dashboardlib/images/common/logo.png",
            alt: "A safe / Always by your side 에이세이프"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "switch_label",
        attrs: { for: "danger_occur_popup_option_switch" }
      },
      [_c("span", { staticClass: "onf_btn" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "weather_item rain" }, [
      _c("div", { staticClass: "rain_icon type01" }, [_vm._v("맑음")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "weather_item rain" }, [
      _c("div", { staticClass: "rain_text" }, [_vm._v("0mm")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "screen_popup" }, [
      _c("div", { staticClass: "popup_box" }, [
        _c("p", { staticClass: "popup_text1" }, [
          _vm._v(" - 안전난간 : 안전 "),
          _c("br"),
          _vm._v(" : 안전난간은 허리높이에 있어야 하고 가운데 난간이 있다. ")
        ]),
        _c("p", { staticClass: "popup_text2" }, [
          _vm._v(" - 안전난간 : 위험 "),
          _c("br"),
          _vm._v(" : 아웃트리거는 펼쳐 져 있어야한다. ")
        ]),
        _c("div", { staticClass: "popup_btn" }, [
          _c("a", { staticClass: "btn type1" }, [_vm._v("모범사례")]),
          _c("a", { staticClass: "btn type2" }, [_vm._v("연결실패")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { attrs: { id: "footer" } }, [
      _c("span", { staticClass: "footer_copy" }, [
        _vm._v("Copyright © AIKL SYSTEM Inc. All Rights Reserved.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }